import { LowerCasePipe, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { Router } from '@angular/router';
import { SvgIconDirective } from '@bs/common/directives/svg-icon.directive';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { AppSettingsService } from '@bs/services/services/core/app-settings.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dropdown-language',
  templateUrl: 'dropdown-language.component.html',
  standalone: true,
  imports: [MatFormField, MatLabel, TranslateModule, MatSelect, MatSelectTrigger, NgTemplateOutlet, MatOption, LowerCasePipe, SlicePipe, SvgIconDirective]
})
export class DropdownLanguageComponent {
  languages = this.config.langs;
  headerLayout = this.config.features?.headerLayout || 'header1';
  currentLang: { name: string, code: string } = { name: '', code: this.config.defaultLangCode };

  constructor(private config: EnvironmentConfig, private appSettingsService: AppSettingsService, private router: Router, private translate: TranslateService) {

    this.currentLang = this.languages.find(x => x.code === translate.currentLang);
    this.appSettingsService.saveSetting('languageCode', translate.currentLang);
  }

  changeLanguage(lang: string) {
    const asAray = this.router.url.split('/');
    const last = asAray.splice(2, asAray.length - 2).join('/');
    //void this.router.navigateByUrl(`/${lang}/${last}`);
    this.translate.use(lang);
    this.currentLang = this.languages.find(x => x.code === lang);
    this.appSettingsService.saveSetting('languageCode', lang);
    window.location.href = `/${lang}/${last}`;
  }
}
